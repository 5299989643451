<template>
    <div>
        <el-row>
            <el-col :span="12" class="align-left">
                <img :src="this.myinfo.intro.resume.logo" alt="个人照片" class="pic" />
            </el-col>
            <el-col :span="12" class="hdrTxt songTypeNumFive">教育部直属国家“211工程”，“双一流”建设高校</el-col>
        </el-row>
        <el-row class="align-center marginb20px">
            <el-col :span="8"> &nbsp; </el-col>
            <el-col :span="8" class="songTypeNumTwo CtrlB">牛&nbsp;迎&nbsp;澳 </el-col>
            <el-col :span="8" class="songTypeNumFive align-right ">男|河北石家庄|工业工程与管理 </el-col>
        </el-row>
        <el-row>
            <el-col :span="8" class="songTypeNumFive align-left ">
                <div class="div1">姓&nbsp;&nbsp;名： 牛迎澳</div>
                <br />
                <div class="div1">毕业院校： 合肥工业大学</div>
                <br />
                <div class="div1">学&nbsp;&nbsp;历： 硕士研究生</div>
                <br />
                <div class="div1 ">成绩排名： <span class="red ">专业第二</span></div>
            </el-col>
            <el-col :span="8" class="songTypeNumFive  align-left">
                <div class="div1">研究方向： <span class="CtrlB  ">智能制造系统监测、智能网联汽车</span></div>
                <br />
                <div class="div1">英语等级： CET 6</div>
                <br />
                <div class="div1">电子邮件： 1481789395@qq.com</div>
                <br />
                <div class="div1">手&nbsp;&nbsp;机： 15511873203</div>
            </el-col>
            <el-col :span="8" class=" ">
                <img :src="this.myinfo.intro.resume.me" class="pic2 align-right " />
            </el-col>
        </el-row>
        <div class=" CtrlB align-left marginleft backgroundcolor marginb20px">教育经历</div>
        <el-row class="orange marginb20px">
            <el-col :span="6" class=" ">时间 </el-col>
            <el-col :span="6" class=" ">学校 </el-col>
            <el-col :span="6" class=" ">专业 </el-col>
            <el-col :span="6" class=" ">学位 </el-col>
        </el-row>
        <el-row v-for="(item, index) in myinfo.intro.resume.educationalExperience" :key="index +'b'" class="songTypeNumFive">
            <el-col :span="6" class=" marginb20px songTypeNumFive">{{item.time}} </el-col>
            <el-col :span="6" class="marginb20px  songTypeNumFive">{{item.school}} </el-col>
            <el-col :span="6" class=" marginb20px songTypeNumFive">{{item.major}} </el-col>
            <el-col :span="6" class=" marginb20px songTypeNumFive">{{item.acadDeg}} </el-col>
        </el-row>

        <div class=" CtrlB align-left marginleft backgroundcolor marginb20px">科研成果</div>
        <el-row class=" marginb20px align-center ">
            <el-col :span="6" class="orange marginb20px ">软件著作权 </el-col>
            <el-col :span="8" class=" marginb20px songTypeNumFive"
                >《智能网联车电性能监测与调试软件 V1.0》
                <div class="marginb20px"></div>
                《基于公有云的TBox系统故障测试诊断软件》</el-col
            >
            <el-col :span="6" class=" marginb20px songTypeNumFive"
                >学生一作
                <div class="marginb20px"></div>
                学生一作</el-col
            >
            <el-col :span="4" class=" marginb20px songTypeNumFive"
                >已授权
                <div class="marginb20px"></div>
                已授权</el-col
            >
        </el-row>
        <div class="CtrlB align-left marginleft backgroundcolor marginb20px">所获奖项</div>
        <el-row v-for="(item, index) in myinfo.intro.resume.prize" :key="index+'c'">
            <el-col :span="6" class=" marginb20px songTypeNumFive">{{item.time}} </el-col>
            <el-col :span="8" class="marginb20px  songTypeNumFive">{{item.name}} </el-col>
            <el-col :span="4" class=" marginb20px songTypeNumFive">{{item.dep}} </el-col>
            <el-col :span="6" class=" marginb20px"
                >{{item.lvl}}<span class="red">{{item.red}}</span>
            </el-col>
        </el-row>
        <div class=" CtrlB align-left marginleft backgroundcolor marginb20px">项目经验</div>
        <el-row class="orange align-center marginb20px">
            <el-col :span="8" class="align-left marginleft brown"> 2022---2023 </el-col>
            <el-col :span="8" class="brown"> 基于云计算的车辆远程测试与诊断系统</el-col>
            <el-col :span="8" class="align-right brown"> 深度参与</el-col>
        </el-row>
        <div class="marginb20px align-left marginleft orange">
            <i class="el-icon-paperclip"></i>
            项目介绍
        </div>
        <div class="marginleft align-left marginleft50 marginb20px lineheight  songTypeNumFive">
           <span class="CtrlB"> 国家自然科学基金资助项目、江淮汽车委托项目；</span>系统支持车辆电性能的<span class="CtrlB">远程测试和可视化展示，</span>支持ISO-14229（Web客户端）、ISO-15765（硬件）等车辆专用诊断协议，可通过云终端硬件与大数据平台的实时协同，实现多模态<span class="CtrlB">车辆数据采集、</span>测试数据注入、异常状态感知等复杂功能，满足整车电性能测试及关键ECU的在线监控、诊断和数据管理需要
        </div>
        <div class="marginb20px align-left marginleft  orange">
            <i class="el-icon-paperclip"></i>
            承担任务
        </div>
        <div class="marginleft align-left marginleft50 marginb20px lineheight songTypeNumFive">
            开发团队web系统前后端部分功能负责人，参与用户需求分析、系统架构设计、数据库设计、web应用软件开发；负责通信协议制定、技术方案设计、文件规范设计、项目版本管理和项目部署。
        </div>

        <div class="marginb20px align-left marginleft orange">
            <i class="el-icon-paperclip"></i>
            主要工作
        </div>
        <div class="marginb20px align-left marginleft ">
            <div class="marginleft align-left marginleft50 marginb20px lineheight songTypeNumFive">
                <i class="el-icon-paperclip"></i
                >设计阶段：参与用户需求分析、诊断功能架构设计、数据库设计、通信协议制定、UDS统一诊断协议栈架构设计、文件规范设计
            </div>
            <div class="marginleft align-left marginleft50 marginb20px lineheight songTypeNumFive">
                <i class="el-icon-paperclip"></i>诊断服务：Java环境下实现CAN总线诊断协议UDS On CAN（ISO
                14229-3）定义的部分服务，如安全解锁服务、会话保持服务、故障码读取和清除服务等；协助硬件设备端实现UDS On
                CAN（ISO 15765-3）协议
            </div>
            <div class="marginleft align-left marginleft50 marginb20px lineheight songTypeNumFive">
                <i class="el-icon-paperclip"></i>OTA系统：参与硬件系统OTA逻辑设计；参与开发了OTA升级管理云平台
            </div>
            <div class="marginleft align-left marginleft50 marginb20px lineheight songTypeNumFive">
                <i class="el-icon-paperclip"></i
                >服务器端程序：在Linux环境下开发基于多线程的通信服务器、远程升级服务器、设备注册服务器，实现所有设备的状态监控、远程配置、数据转发及离线测试数据提取等功能；实现设备的测试与诊断程序的OTA功能；实现设备的自动注册认证功能。在服务器安装MySQL、Redis等应用，配置服务器程序运行环境，部署各服务器程序，设置各程序自启动和异常退出后的自动启动。
            </div>
            <div class="marginleft align-left marginleft50 marginb20px lineheight songTypeNumFive">
                <i class="el-icon-paperclip"></i
                >维护工作：解决设备无法长时间安全记录的问题，解决前端若干显示bug和CAN时间戳显示错误，新增内存显示功能
            </div>
        </div>
        <hr />
        <el-row class="orange align-center marginb20px">
            <el-col :span="8" class="align-left marginleft brown "> 2023---至今 </el-col>
            <el-col :span="8" class=" brown "> 基于工业5G的商用车T-BOX测试系统</el-col>
            <el-col :span="8" class="align-right brown "> 核心参与</el-col>
        </el-row>
        <div class="marginb20px align-left marginleft orange">
            <i class="el-icon-paperclip"></i>
            项目介绍
        </div>
        <div class="marginleft align-left marginleft50 marginb20px lineheight songTypeNumFive">
            江淮汽车、中国移动委托项目，项目由测试台架、云端服务器和大数据云平台三部分组成，旨在提高车企T-Box测试自动化程度，满足商用车
            T-BOX 台架和整车测试需要，实现多地点、多车型、多场景下商用车T-BOX 的高可靠、高效率的测试与验证
        </div>
        <div class="marginb20px align-left marginleft  orange">
            <i class="el-icon-paperclip"></i>
            承担任务
        </div>
        <div class="marginleft align-left marginleft50 marginb20px lineheight songTypeNumFive">
            开发团队嵌入式系统后期负责人，江淮汽车与北京汽车的联络人，参与用户需求分析、系统架构设计；负责通信协议优化、文件格式改进、下位机程序维护。
        </div>

        <div class="marginb20px align-left marginleft orange">
            <i class="el-icon-paperclip"></i>
            主要工作
        </div>
        <div class="marginb20px align-left marginleft ">
            <div class="marginleft align-left marginleft50 marginb20px lineheight songTypeNumFive">
                <i class="el-icon-paperclip"></i>
                设计阶段：参与用户需求分析、系统架构设计、测试架构设计、数据库设计、通信协议制定、文件规范设计、企业交接
            </div>
            <div class="marginleft align-left marginleft50 marginb20px lineheight songTypeNumFive">
                <i class="el-icon-paperclip"></i>
                实施阶段：担任web系统、pytest框架负责人，使用vue、前后端分离等技术，设计了web系统的前后端部分和测试框架的设计
            </div>
            <div class="marginleft align-left marginleft50 marginb20px lineheight songTypeNumFive">
                <i class="el-icon-paperclip"></i>
                关键技术：测试框架选用pytest框架，实现CAN数据解析，根据不同指令和配置执行用例，前端实现了自动解析dbc文件，可视化配置参数等自研功能。
            </div>
        </div>

        <div class=" CtrlB align-left marginleft backgroundcolor marginb20px">专业技能</div>
        <el-row v-for="(item, index) in myinfo.intro.resume.professionalSkills" :key="index+'d'">
            <div class="align-left div1 "><i class="el-icon-position"></i> {{item}}</div>
        </el-row>
        <div class=" CtrlB align-left marginleft backgroundcolor marginb20px margint20px">自我认知</div>
        <el-row v-for="(item, index) in myinfo.intro.resume.myself" :key="index +'a'">
            <div class="align-left div1 ">
                <i
                    class="el-icon-paperclip
"
                ></i>
                <span>{{item.blod}}</span
                ><span class="songTypeNumFive">{{item.txt}}</span>
            </div>
        </el-row>
    </div>
</template>

<script>
import '@/assets/resume/resume.css'
export default {
    name: 'Resume',
    data() {
        return {
            logo: require('@/assets/resume/hfutlogo.png'),
        }
    },
    methods: {},
}
</script>

<style>
</style>