// 所以关于你的信息都在这配置, 之后会自动放入项目中
export default {
    global: {
        title: '牛迎澳的在线简历', // 浏览器标签页名 和 footer中的版权内容
        name: '牛迎澳', // 你的名字
        nickName: 'LaoAniu', // 英文名或昵称
    },

    header: {
        // 介绍自己的几个主题
        intro_items: [
            // {
            //     title: "个人介绍",
            //     anchor: "#home",
            // },
            {
                title: '关于我',
                anchor: '#aboutme',
            },
            {
                title: '在线简历',
                anchor: '#resume',
            },
            {
                title: '我会些什么?',
                anchor: '#skills',
            },
            {
                title: '个人作品',
                anchor: '#works',
            },
            {
                title: '联系我',
                anchor: '#contact',
            },
        ],
    },

    intro: {
        aboutme: {
            me: require('@/assets/resume/shuaime.jpg'), // 上半身照
            introduce: `
            在合肥工业大学完成了工业工程本硕学业，成绩优异，获得多项奖学金及省级竞赛奖项，并发表了两项软件著作权。研究生期间主要参与了实验室两个项目,当前担任T-Box项目的负责人，负责的项目任务为web客户端软件开发、功能架构设计、数据库设计、通信协议制定版本管理以及项目部署等功能，我擅长使用主流办公软件及AI工具，对互联网新兴技术保持高度关注。同时，我也热爱视频剪辑与PS设计。我性格开朗、学习能力强、喜欢挑战、善于与人合作、思维活跃、胆大心细的人，曾本科毕业时买了一辆山地车毫无经验的一个人从校门口骑到了海南儋州，我敢于尝试不惧困难。\n未来，我期望能在车辆技术或软件工程领域发挥专长，贡献自己的力量！`,
        },
        resume: {
            logo: require('@/assets/resume/hfutlogo.png'),
            me: require('@/assets/resume/me.png'),
            educationalExperience: [
                { time: '2022/09—至今', school: '合肥工业大学', major: '工业工程与管理', acadDeg: '工学硕士' },
                { time: '2018/09—2022/06', school: '合肥工业大学', major: '工业工程', acadDeg: '工学学士' },
            ],
            prize: [
                {
                    time: '2024/02',
                    name: '安徽合力创新创业奖学金',
                    dep: '机械工程学院',
                    lvl: '奖学金',
                    red: '（省级）',
                },
                {
                    time: '2023/11',
                    name: '2023年ICAN大学生创新创业大赛',
                    dep: '中国信息协会',
                    lvl: '省级二等奖',
                    red: '(核心成员)',
                },
                {
                    time: '2023/07',
                    name: '第八届安徽省“互联网+”大学生创新创业大赛',
                    dep: '安徽省“互联网+”组委会',
                    lvl: '安徽省金奖',
                    red: '(核心成员)',
                },
                {
                    time: '2023/11',
                    name: '安徽省高校物联网应用创新大赛',
                    dep: '安徽省计算机协会',
                    lvl: '安徽省银奖',
                    red: '(负责人)',
                },
                {
                    time: '2023/11',
                    name: '2023-2024学年三好学生',
                    dep: '合肥工业大学',
                    lvl: '三好学生',
                    red: '（校级）',
                },
                {
                    time: '2023/11',
                    name: '合肥工业大学硕士研究生学业奖学金',
                    dep: '合肥工业大学',
                    lvl: '一等奖学金',
                    red: '(专业第二)',
                },
                {
                    time: '2022/11',
                    name: '合肥工业大学硕士研究生学业奖学金',
                    dep: '合肥工业大学',
                    lvl: '一等奖学金',
                    red: '(专业第三)',
                },
            ],
            professionalSkills: [
                '了解汽车CAN总线相关知识；熟悉ISO 14229和15765协议，参与开发过14229诊断协议栈和部分服务',
                '掌握Java 、python、kotlin和vue编程语言以及LayUi、elementUi和echarts等前端开发组件和掌握 Spring、Spring Boot、MyBatis 等后端开源框架，可实现web系统前后端开发；',
                '熟悉 MySQL、Redis 数据库、计算机网络、操作系统、数据结构等基础知识',
                '熟悉发明软件著作权的申请流程，擅长软著和项目申报书等文档的撰写',
                '熟练使用Microsoft Office、 Visio、、Anylogic 仿真、PS和SolidWorks等办公和设计软件，并掌握图片处理和视频剪辑技能，具有良好的基础办公能力；',
                '善于使用AI工具，如Chat GPT4、Claude和GitHub Copilot Chat等主流国外AI工具，并有私人ChatGpt网站',
            ],
            myself: [
                {
                    blod: '数学能力好',
                    txt: '，善于实际问题的数学建模，高考数学142、本科矩阵理论99分、考研数学仅错一道选择；',
                },
                { blod: '表达能力强，有较强的领导能力', txt: '，多次担任企业调研学生团队负责人；' },
                {
                    blod: '心态积极外向、具有较强的抗压能力、积极对待工作学习',
                    txt: '，乐于钻研有挑战性、创新性的课题和项目',
                },
                {
                    blod: '乐于助人，责任感强',
                    txt: '，主动承担本实验室和其他实验室的数码产品购买指导、系统安装和软件安装；',
                },
                { blod: '敢于尝试、不具困难', txt: '，一年跑步减肥20斤，曾零基础单人骑行2200km从合肥至海南儋州' },
            ],
            project: [
                {
                    // firstLine:["2022---2023","基于云计算的车辆远程测试与诊断系统","深度参与"],
                    // content:[{
                    //     name:"",
                    //     content:"",
                    // },{
                    //     name:"",
                    //     content:"",
                    // },],
                    work: {
                        name: '',
                        content: [],
                    },
                },
            ],
        },
        home: {
            expect_position: 'Web前端开发(实习生)', // 期望岗位
            me: require('@/assets/home/me.png'), // 你的头像的路径, 注意储存路径及其写法, @/ 等价于 src/
            blogs: {
                cnblogs: 'https://www.cnblogs.com/fitzlovecode/', // 博客园地址
                gitee: 'https://gitee.com/chinafitz',
                github: 'https://github.com/ChinaFitz',
            },
        },

        skills: {
            // 最熟悉的技术栈一般3-5个
            /* 
                该项目可以直接使用 https://boxicons.com/ 中的icon 和 https://element.eleme.cn/#/zh-CN/component/icon 中的 icon, 只需要bxs-icon-name即可
                使用默认icon就用undefined代替
                不适用icon就用""代替
            */
            main_skills: [
                {
                    skill_name: '前后端开发',
                    progress_of_master: 80, // 技能掌握程度
                    icon: 'bxl-html5', // icon类名
                },
                {
                    skill_name: 'CAN总线',
                    progress_of_master: 85,
                    icon: 'bxl-css3',
                },
                {
                    skill_name: 'Java',
                    progress_of_master: 80,
                    icon: 'bxl-javascript',
                },
                {
                    skill_name: 'Mysql',
                    progress_of_master: 70,
                    icon: undefined, // 使用默认icon
                },
                {
                    skill_name: 'Redis',
                    progress_of_master: 55,
                    icon: 'bxl-nodejs',
                },
                {
                    skill_name: 'Python',
                    progress_of_master: 65,
                    icon: 'bxl-python',
                },
                {
                    skill_name: 'Git',
                    progress_of_master: 70,
                    icon: 'bxl-git',
                },{
                    skill_name: 'nginx',
                    progress_of_master: 70,
                    icon: 'bxl-git',
                },{
                    skill_name: 'docker',
                    progress_of_master: 70,
                    icon: 'bxl-git',
                },
               
            ],
            //其他一些能够说得上东西的技能, 最好列举5个
            sub_skills: [
          
                {
                    skill_name: '视频剪辑 图片处理',
                    progress_of_master: 80,
                    icon: 'bxl-less',
                },
                {
                    skill_name: '办公软件使用',
                    progress_of_master: 80,
                    icon: 'bxl-bootstrap',
                },
          
                {
                    skill_name: '文档撰写排版',
                    progress_of_master: 90,
                    icon: 'bxl-vuejs',
                },
                {
                    skill_name: 'AI工具',
                    progress_of_master: 85,
                    icon: undefined,
                },{
                    skill_name: '电脑装机',
                    progress_of_master: 90,
                    icon: undefined,
                },{
                    skill_name: '重装系统',
                    progress_of_master: 90,
                    icon: undefined,
                },{
                    skill_name: '办公自动化',
                    progress_of_master: 80,
                    icon: undefined,
                },{
                    skill_name: '系统文件清理',
                    progress_of_master: 80,
                    icon: undefined,
                },{
                    skill_name: '动手能力',
                    progress_of_master: 80,
                    icon: undefined,
                },
            ],
            // 对自己会的技能做简单的介绍
            skills_introduction: `
                项目方面的知识主要是从网上找教学视频或看师兄留下的文档进行学习，然后写demo边实践边学习，我会把学习的知识用语雀软件总结起来，我十分喜欢新兴的技术，时刻关注着新软件和技术的发布，我善于发现项目和日常生活中的效率瓶颈并解决它，此外我动手能力很强，比如我制造了一键连接校园网脚本、快速填写简历信息工具、无线充电相框、放丢失校园卡手机壳、diy鼠标……
            `,
        },

        works: [
            {
                title: '找"直播网红"平台用户端(前后端分离)',
                pic: require('@/assets/works/wanghong.png'),
                url: '',
            },
            {
                title: '好物商城(前后端分离)',
                pic: require('@/assets/works/mall.png'),
                url: '',
            },
            {
                title: '在线简历(响应式布局)',
                pic: require('@/assets/works/resume.png'),
                url: '',
            },
            {
                title: '子网划分辅助工具(纯静态)', // 作品标题
                pic: require('@/assets/works/subnet.png'), // 作品预览图
                url: '', // 项目运行地址, 如果未部署可以用 javascript:; 代替
            },
        ],

        contact: {
            full_info: [
                {
                    item: '姓名',
                    content: '牛迎澳',
                    icon: 'el-icon-user', // 注意不同网站icon的用法, 具体可以去网站中查看
                },
                {
                    item: '电话号码',
                    content: '15511873203',
                    icon: 'el-icon-mobile-phone',
                },
                {
                    item: '微信号',
                    content: '15511873203',
                    icon: 'el-icon-chat-round',
                },
                {
                    item: '邮箱',
                    content: '1481789395@qq.com',
                    icon: 'el-icon-location-outline',
                },
                // {
                //     item: "居住地",
                //     content: "中山市",
                //     icon: "bx bx-mail-send",
                // },
            ],
        },
    },

    footer: {
        record_num: '粤ICP备2021178257号', // 备案号
    },
}
