<template>
    <div id="intro_container">
        <IntroItem class="intro_items" ref="intro_items" v-for="(item, index) in intro_items" :key="index"
            :slot_name="item.anchor">

            <!-- 介绍项 -->
            <template #aboutme>
                <p class="title animate__animated animate__bounce animate__fadeInRightBig ">{{ item.title }}</p>
                <div class="whoami animate__animated animate__bounce animate__fadeInLeftBig">
                    <img :src="me" alt="个人照片" class="pic" />
                    <div class="introduce">
                        <p>我叫 {{ myinfo.global.name }} ,</p>
                        <div>{{ myinfo.intro.aboutme.introduce }}</div>
                        <!-- 新增的 -->
                        <div class="contactniu ">
                            <a href="#contact" class="contract_me">联系我吧</a>
                            <!-- <div class="blogs ">
                                <a class="blog_item" target="_blank" :href="blogs.cnblogs"><i
                                        class="bx bxs-phone"></i>&nbsp;电话/微信</a>
                                <a class="blog_item" target="_blank" :href="blogs.github"> B站 </a>
                                <a class="blog_item" target="_blank" :href="blogs.github"> 邮箱 </a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </template>
            <!-- 在线简历 -->
            <template #resume>
                <p class="title animate__animated animate__bounce animate__fadeInRightBig ">{{ item.title }}</p>
                <!-- 下载按钮 -->
                <div style="width: 100%;">
                    <el-row :gutter="20" style="width: 100%; margin-bottom: 20px;">
                        <el-col :span="3"><span>&nbsp;</span></el-col>
                        <el-col :span="18" style="text-align: right;">
                            <el-button type="primary" @click="downloadFile">下载</el-button>
                        </el-col>
                        <el-col :span="3" style="text-align: center;">
                            <span>&nbsp;</span>
                        </el-col>
                    </el-row>
                </div>
                <!-- 简历 -->
                <div style="width: 100%;">
                    <el-row :gutter="20" style="width: 100%;">
                        <!-- <el-col :span="1"><span>&nbsp;</span></el-col> -->
                        <el-col :span="24" style="text-align: center;">
                            <el-card class="Resumecontainer">
                                <!-- <Resume></Resume> -->
                                <!-- <pdf ref="pdf" src='/牛迎澳-硕士-合肥工业大学-工业工程与管理.pdf' :page="1"> </pdf>
                                <pdf ref="pdf" src='/牛迎澳-硕士-合肥工业大学-工业工程与管理.pdf' :page="2"> </pdf> -->
                                <div class="demo-image__preview">
                                    <el-image style="width: 100%; height: 100%" :src="url" :preview-src-list="srcList"
                                        fit="fill">
                                    </el-image>
                                    <el-image style="width: 100%; height: 100%" :src="url2" :preview-src-list="srcList"
                                        fit="fill">
                                    </el-image>
                                </div>
                                <p style="text-align: center;">点击图片放大</p>

                            </el-card>
                        </el-col>
                        <!-- <el-col :span="1"><span>&nbsp;</span></el-col> -->
                    </el-row>
                </div>
            </template>

            <!-- skill(我会些什么)介绍项 -->
            <template #skills>
                <p class="title animate__animated animate__bounce animate__fadeInRightBig ">{{ item.title }}</p>
                <div class="container">
                    <div class="skills_left">
                        <p>我掌握了以下技能:</p>
                        <div class="skills_introduce">
                            {{ myinfo.intro.skills.skills_introduction }}
                        </div>
                        <ul class="skills_list animate__animated animate__bounce animate__fadeInLeftBig">
                            <Skills v-for="(skill, index) in main_skills" :key="index" :skill_name="skill.skill_name"
                                :progress_num="skill.progress_of_master" :icon_class_name="skill.icon" />
                        </ul>
                    </div>
                    <div class="skills_right">
                        <ul class="skills_list animate__animated animate__bounce animate__fadeInRightBig">
                            <Skills v-for="(skill, index) in sub_skills" :key="index" :skill_name="skill.skill_name"
                                :progress_num="skill.progress_of_master" :icon_class_name="skill.icon" />
                        </ul>
                    </div>
                </div>
            </template>
            <!-- 项目介绍 -->
            <template #works>
                <p class="title animate__animated animate__bounce animate__fadeInRightBig ">{{ item.title }}</p>

                <el-card box-shadow="0 2px 4px rgba(0, 0, 0, 0.6)" style="text-align: center;">
                    <el-image class="custom-image" style="width: 60%; height: 10rem" :src="url_pro1" :preview-src-list="url_pro1_list"
                        fit="fill ">
                    </el-image>
                    <el-divider></el-divider>
                    <el-image  class="custom-image" style="width: 60%; height: 10rem" :src="url_pro2" :preview-src-list="url_pro2_list"
                        fit="fill ">
                    </el-image>
                    <el-image  class="custom-image" style="width: 60%; height: 10rem" :src="url_pro3" :preview-src-list="url_pro3_list"
                        fit="fill ">
                    </el-image>
                    <p style="text-align: center;">点击图片放大</p>
                </el-card>
            </template>

            <!-- contact(联系我)介绍项 -->
            <template #contact>
                <p class="title animate__animated animate__bounce animate__fadeInRightBig ">{{ item.title }}</p>

                <el-descriptions border :column="1" size="size">
                    <el-descriptions-item v-for="(info, index) in full_info" :key="index" ref="infos">
                        <template slot="label">
                            <i :class="info.icon"></i>
                            {{ info.item }}
                        </template>
                        {{ info.content }}

                        <el-button type="primary" size="mini" @click="copy(info.content)">
                            点击复制信息
                        </el-button>

                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-video-play"></i>
                            B站
                        </template>
                        LaoAniu
                        <el-button type="primary" size="mini" @click="toB()">
                            点击跳转B站
                        </el-button>

                    </el-descriptions-item>
                </el-descriptions>
            </template>
        </IntroItem>

        <el-backtop :visibility-height="50"></el-backtop>
    </div>
</template>

<script>
import lodash from 'lodash'
import IntroItem from '@/components/IntroItem'
import Skills from '@/components/Skills'
import Resume from '@/components/Resume'
import Project from '@/components/Project'
import pdf from 'vue-pdf'

export default {
    name: 'Intro',
    components: {
        IntroItem,
        Skills,
        Resume,
        Project,
        pdf,
    },
    mounted() {
        this.intro_item_active()
    },
    data() {
        return {
            avatar: this.myinfo.intro.home.me,
            me: this.myinfo.intro.aboutme.me,
            pdfurl: './',
            url: '/static/牛迎澳-硕士-合肥工业大学-工业工程与管理_1.jpg',
            url2: '/static/牛迎澳-硕士-合肥工业大学-工业工程与管理_2.jpg',
            srcList: [
                '/static/牛迎澳-硕士-合肥工业大学-工业工程与管理_1.jpg',
                '/static/牛迎澳-硕士-合肥工业大学-工业工程与管理_2.jpg',
            ],
            url_pro1: '/static/项目截图/登录界面.jpg',
            url_pro1_list: [
                '/static/项目截图/登录界面.jpg',
                '/static/项目截图/系统首页.jpg',
                '/static/项目截图/系统首页2.jpg',
                '/static/项目截图/img-4.png',
                '/static/项目截图/img-5.png',
                '/static/项目截图/img-6.png',
                '/static/项目截图/img-7.png',
                '/static/项目截图/img-8.png',
                '/static/项目截图/img-9.png',
                '/static/项目截图/img-10.png',
                '/static/项目截图/img-11.png',
                '/static/项目截图/img-12.png',
                '/static/项目截图/img-13.png',
            ],
            url_pro2: '/static/项目截图/T-Box系统截图/系统首页.png',
            url_pro2_list: [
                '/static/项目截图/T-Box系统截图/系统首页.png',
                '/static/项目截图/T-Box系统截图/工位选择.png',
                '/static/项目截图/T-Box系统截图/参数配置.png',
                '/static/项目截图/T-Box系统截图/测试过程中.png',
                '/static/项目截图/T-Box系统截图/测试报表.png',
                '/static/项目截图/T-Box系统截图/台架管理.png',
                '/static/项目截图/T-Box系统截图/项目管理.png',
                '/static/项目截图/T-Box系统截图/用例管理.png',
            ],
            url_pro3: '/static/项目截图/茅台/5.png',
            url_pro3_list: [
                '/static/项目截图/茅台/1.png',
                '/static/项目截图/茅台/2.png',
                '/static/项目截图/茅台/3.png',
                '/static/项目截图/茅台/4.png',
            ],
        }
    },
    computed: {
        intro_items() {
            const reg = /(\w+)/g
            const items = this.myinfo.header.intro_items
            console.info(items)
            return items.map(item => {
                return {
                    title: item.title,
                    anchor: item.anchor.match(reg)[0],
                }
            })
        },
        // 博客相关的地址
        blogs() {
            return this.myinfo.intro.home.blogs
        },
        skills_list() {
            return this.myinfo.intro.skills
        },
        main_skills() {
            return this.skills_list.main_skills
        },
        sub_skills() {
            return this.skills_list.sub_skills
        },
        works_list() {
            return this.myinfo.intro.works
        },
        full_info() {
            return this.myinfo.intro.contact.full_info
        },
    },
    methods: {
        downloadFile() {
            let a = document.createElement('a');
            a.href = `/static/牛迎澳-硕士-合肥工业大学-工业工程与管理.pdf`;
            a.download = '牛迎澳-硕士-合肥工业大学-工业工程与管理.pdf';
            a.click();
        },
        // 用于头部和移动端侧边栏的锚点响应式, 即: 浏览到对应的介绍项目时, 对应介绍项就有下划线高亮
        intro_item_active() {
            const vcs = this.$refs['intro_items']
            const anchor_target = {} // 记录每个锚点所在的位置, 用于顶部和移动端右侧菜单的响应式
            const items = this.myinfo.header.intro_items
            vcs.forEach((vc, index) => {
                const div = vc.$el
                anchor_target[`${div.offsetTop}`] = items[index]['anchor']
            })

            addEventListener(
                'scroll',
                lodash.throttle(() => {
                    // +header的高度 的原因是: 修复锚点因为header的position为sticky而导致的偏移bug
                    let curScrollTop = document.documentElement.scrollTop + 72
                    let anchors_scrollTop = Object.keys(anchor_target)
                    for (let i = 0; i < anchors_scrollTop.length; i++) {
                        const cur_anchor_scrollTop = Number(anchors_scrollTop[i])
                        const next_anchor_scrollTop = Number.isNaN(Number(anchors_scrollTop[i + 1]))
                            ? cur_anchor_scrollTop + (cur_anchor_scrollTop - Number(anchors_scrollTop[i - 1]))
                            : Number(anchors_scrollTop[i + 1])
                        if (curScrollTop >= cur_anchor_scrollTop && curScrollTop < next_anchor_scrollTop) {
                            const flag = `${anchors_scrollTop[i]}`
                            this.$bus.$emit('change_hash', anchor_target[flag])
                            break
                        }
                    }
                }, 200)
            )
        },

        // 实现一键复制(仅支持PC端)
        copy(text) {
            navigator.clipboard.writeText(text)
            this.$message({
                message: '已复制',
                type: 'success',
            })
        },
        toB() {
            window.open("https://space.bilibili.com/351612366?spm_id_from=333.1007.0.0");
        }
    },
}
</script>

<style scoped lang="less">
@import '../config.less';

#intro_container {
    width: 90%;
    margin: 0 auto;

    /* 将样式穿透到IntroItem组件(具名插槽)中 */
    ::v-deep .intro_items {
        color: @intro_font_color;
        font-weight: bold;
    }
}
</style>
<style>
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
</style>
<style scoped>
.custom-image {
  border: 1px solid #ccc; /* 边框颜色和宽度 */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3); /* 阴影效果 */
  border-radius: 2px; /* 边框圆角 */
}
</style>
