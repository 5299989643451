import Vue from 'vue'
import App from '@/App.vue'

// 按需引入elementUI
// import "@/utils/use_elementUi"
//全部引用
import Element from 'element-ui'

// 个人信息
import myinfo from "@/myInfo"
// 全局字体
import "@/assets/font.less"
// 清除移动端样式
import "@/assets/resetMobile.less"
// 全局的动画样式库
import 'animate.css'
// boxicons图标库
import 'boxicons/css/boxicons.min.css';
Vue.use(Element)

// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
// Vue.use(Viewer)
// Viewer.setDefaults({
//   // 需要配置的属性 注意属性并没有引号
//   title: false,
//   toolbar: false
// })


Vue.config.productionTip = false

new Vue({
    render: createEle => createEle(App),
    el: '#app',
    beforeCreate() {
        Vue.prototype.myinfo = myinfo
        Vue.prototype.$bus = this
        document.title = myinfo.global.title
    },
})
