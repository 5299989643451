<template>
    <div id="footer_container">
        <p id="title">
            {{ myinfo.global.title }}
        </p>
        <div style="display: flex; " class="link">
            <a
                target="_blank"
                href="https://space.bilibili.com/351612366?spm_id_from=333.1007.0.0"
                class="link"
                style="display: flex; margin:10px  ; flex-direction: column;"
            >
                <img src="../assets/resume/bilibili-line.png" alt="Fitz" class="img" />
                <span class="txt">bilibili</span>
            </a>
            <a
                href="#"
                @click="copyToClipboard"
                class="link"
                style="margin:10px ; display: flex; flex-direction: column;"
            >
                <img src="../assets/resume/抖音.png" alt="Fitz" class="img" />
                <span class="txt">抖音</span>
            </a>
            <a
                href="#"
                @click="copyToClipboard1"
                class="link"
                style="margin:10px ; display: flex; flex-direction: column;"
            >
                <img src="../assets/resume/github-fill.png" alt="Fitz" class="img" />
                <span class="txt">github</span>
            </a>
        </div>
        <a style="color: aliceblue; margin-top: 20px;text-decoration: none;" href="https://beian.miit.gov.cn/" target="https://beian.miit.gov.cn/">冀ICP备2024084234号-1</a>

    </div>
</template>

<script>
export default {
    name: 'MyFooter',
    computed: {
        record_num() {
            return this.myinfo.footer.record_num
        },
    },
    methods: {
        copyToClipboard(event) {
            event.preventDefault() // prevent the default action
            navigator.clipboard
                .writeText(
                    '9- 长按复制此条消息，打开抖音搜索，查看TA的更多作品。 https://v.douyin.com/i2VkSnrM/ 9@2.com :7pm'
                )
                .then(() => {
                    console.log('Text copied to clipboard')
                })
                .catch(err => {
                    console.error('Could not copy text: ', err)
                })

            this.$message({
                message: '已复制链接请打开抖音APP',
                type: 'success',
            })
        },
        copyToClipboard1(event) {
            event.preventDefault() // prevent the default action
            this.$message('github没啥仓库,就不展示了');

        },
    },
}
</script>

<style scoped lang="less">
@import '../config.less';
.txt {
    color: #ffffff;
    text-decoration: none;
}
.img {
    height: 60px;
}
.link {
    justify-content: center; /* 新添加的代码 */
}
#footer_container {
    color: @footer_font_color;
    font-size: @footer_font_size;
    font-weight: 500;

    p {
        margin: 25px;
    }
}

#title {
    font-size: 1.5rem;
}
</style>
